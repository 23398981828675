<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <router-link :to="{ name: 'clients_all' }">
                <el-button type="primary">
                    {{ $t('clients.go_back') }}
                </el-button>
            </router-link>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
        </div>

        <Table :data="clientsData" @restore="handleRestore" @delete="handleDelete" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>
// import filter from 'lodash/filter';
export default {
    components: {
        Table: () => import(/* webpackChunkName: "ClientsArchivedTable" */ './ClientsArchivedTable'),
    },

    data() {
        return {
            clientsData:       [],
            clientsDataOrigin: [],
            quickSearch:       '',
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_clients_archive');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('clients.all_clients_archive'));
        this.$store.commit('setActiveMenuItem', '1-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.quickSearch = '';
            this.$wait.start('loading');

            const clients = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/archived`);
            this.clientsData = Object.freeze(clients.data);
            this.clientsDataOrigin = Object.freeze(clients.data);

            this.$wait.end('loading');
            this.$wait.end('loading.initial_clients_archive');
        },

        filter() {
            this.clientsData = this.clientsDataOrigin.filter(client => String(client.id).includes(this.quickSearch.toLowerCase())
                    || String(client.custom_id).toLowerCase().includes(this.quickSearch.toLowerCase())
                    || client.name.toLowerCase().includes(this.quickSearch.toLowerCase())
                    || (client.surname && client.surname.toLowerCase().includes(this.quickSearch.toLowerCase()))
                    || (client.type && this.$t(`clients.${client.type}`).toLowerCase().includes(this.quickSearch.toLowerCase())));
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${uuid}/destroy`,
                actionType: 'destroy',
            });
        },

        async handleRestore(uuid) {
            this.$wait.start('loading');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${uuid}/restore`);
                this.$notify.success({ title: 'Success' });
                this.$wait.end('loading');
                this.getAll();
            } catch (error) {
                this.$wait.end('loading');
            }
        },
    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
    .el-button--mini {
        padding: 5px 5px;
    }
</style>
